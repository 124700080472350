
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  apiUrl : 'https://appbox.itmizer.com.br/',
  //apiUrl : 'http://192.168.2.116:3000/',
  urlEquipament : 'http://localhost:7000',
  //username : 'admin',
  //password : 'leo232373',
  username : 'itmizer',
  password : '@itmizer#408',
//apiUrl: 'http://192.168.2.13:3000/',

 firebaseConfig: {
  apiKey: "AIzaSyAPNFlWGVqVKCC-UbnH-fGiz8Nzx4cOM5c",
  authDomain: "summer-c3409.firebaseapp.com",
  projectId: "summer-c3409",
  storageBucket: "summer-c3409.appspot.com",
  messagingSenderId: "576179477280",
  appId: "1:576179477280:web:33289c8b374664939b1545",
  measurementId: "G-S7XRQ0KFYH"
 },
 webClientId: '576179477280-g7ml4q4lsr57i44l6diahpsh4kbisetl.apps.googleusercontent.com'
};
export const SERVER_URL = 'http://localhost:8080';

